<script setup>
import AppLayout from '@/layouts/AppLayout.vue'
</script>

<template>
    <AppLayout without-go-back :title="$t('home')">
        <template v-if="$root.is_japanese">
            <div>
                <div class="mx-auto max-w-7xl py-6 sm:px-6 sm:py-4 lg:px-8">
                    <div
                        class="relative isolate overflow-hidden bg-indigo-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
                        <h1
                            class="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
                            電子券・パスプラットフォームKINCHAKU
                        </h1>
                        <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
                            KINCHAKUで発行されるパスはApple WalletとGoogle
                            Walletに対応しており、独自のアプリケーションをダウンロードする必要がありません。
                        </p>
                        <div class="mt-4 flex items-center justify-center gap-x-6">
                            <a
                                href="https://help.kinchaku.com/category/226-category"
                                class="text-sm font-semibold leading-6 text-cyan-300"
                                >利用者アカウントとパス利用について
                                <span aria-hidden="true">→</span></a
                            >
                        </div>
                        <h2
                            class="mx-auto max-w-2xl text-2xl font-bold tracking-tight text-white sm:text-4xl mt-24">
                            店舗オーナー向け
                        </h2>
                        <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
                            KINCHAKUは、紙での発券や管理が不要となるデジタルパスの発行・販売、予約・決済、顧客・売上管理やリピータ獲得等をワンストップで行えます。
                        </p>
                        <div class="mt-10 flex items-center justify-center gap-x-6">
                            <a
                                href="https://www.kinchaku.com/features?mtm_campaign=storefront"
                                alt="KINCHAKUサービス概要を見る"
                                class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                                >サービス概要を見る</a
                            >
                        </div>
                        <svg
                            viewBox="0 0 1024 1024"
                            class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                            aria-hidden="true">
                            <circle
                                cx="512"
                                cy="512"
                                r="512"
                                fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                                fill-opacity="0.7" />
                            <defs>
                                <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                                    <stop stop-color="rgb(129 140 248)" />
                                    <stop offset="1" stop-color="rgb(165 180 252)" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="bg-white">
                <div class="relative isolate px-6 lg:px-8">
                    <div class="mx-auto max-w-2xl py-8 sm:py-4 lg:py-12">
                        <div class="text-center text-gray-600">
                            <h1
                                class="text-2xl font-bold uppercase text-indigo-700 tracking-tight sm:text-4xl">
                                KINCHAKU. <br />
                                <span class="text-cyan-500">Your digital pass concierge.</span>
                            </h1>

                            <div class="mt-10">
                                <h2 class="font-bold text-xl mb-3">
                                    Are you tired of carrying around a bulky wallet full of cards?
                                </h2>

                                <p class="text-base leading-8 text-gray-600 mb-4">
                                    KINCHAKU is here to help! KINCHAKU is a service for storing
                                    non-payment cards, such as membership cards, reward cards,
                                    coupons, and tickets.
                                </p>
                                <p class="text-base leading-8 text-gray-600 mb-4">
                                    KINCHAKU also supports digital passes which can be added
                                    directly to Apple Wallet or Google Wallet. You don't even have
                                    to worry about keeping track of physical tickets; all you need
                                    is your phone and you'll be able to access all your passes
                                    quickly and easily.
                                </p>
                                <p class="text-base leading-8 text-gray-600 mb-8">
                                    KINCHAKU makes it simple for users to transfer information
                                    between devices - whether they're using a laptop, tablet, or
                                    smartphone - making sure that all your data is always available
                                    regardless of where you go. No matter what device you use, your
                                    valuable card data remains safe and accessible at all times.
                                </p>
                                <h3 class="font-bold text-center text-xl">
                                    No need to print your tickets or coupons, save the environment,
                                    and go digital.
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </AppLayout>
</template>
